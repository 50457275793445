export const experienceData = [

    {
        id: 1,
        company: 'Disruptive Studio',
        jobtitle: 'Project Manager',
        startYear: '2024',
        endYear: '2024'
    },
    {
        id: 3,
        company: 'milktech',
        jobtitle: 'Co founder',
        startYear: '2022',
        endYear: '2024'
    },
    {
        id: 3,
        company: 'Betterware de México',
        jobtitle: 'DevOps Tech Lead',
        startYear: '2021',
        endYear: '2022'
    },
    {
        id: 4,
        company: 'Freelance',
        jobtitle: 'Freelance',
        startYear: '2020',
        endYear: '2021'
    },
    {
        id: 5,
        company: 'Arrendamas',
        jobtitle: 'Project Manager',
        startYear: '2018',
        endYear: '2020'
    },
    {
        id: 6,
        company: 'Grupo TYC',
        jobtitle: 'Chief Systems',
        startYear: '2018',
        endYear: '2018'
    },
    
]